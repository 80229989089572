import { atom } from 'recoil';

export const commerceEnabled = atom<boolean | undefined>({
  key: 'commerceEnabled',
  default: undefined,
});

export const commerceLoggedIn = atom<boolean | undefined>({
  key: 'commerceLoggedIn',
  default: undefined,
});
