import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { commerceLoggedIn as commerceLoggedInAtom } from 'Atoms/commerce';
import useCart from '@framework/cart/use-cart';
import dynamic from 'next/dynamic';
import { useCommerceService } from 'Hooks/CommerceService';
import { CommerceProvider } from '@framework';
import { DemiplaneAuthUser } from 'src/lib/serverSidePropsAuthCheck';

type CommerceConfigProps = {
  currentUser: DemiplaneAuthUser;
};

const CommerceServiceProvider = dynamic(
  () => {
    return import('src/state/providers/CommerceServiceProvider').then((mod) => {
      return mod.CommerceServiceProvider;
    });
  },
  { ssr: false }
);

export const CommerceConfigBase = React.memo(
  ({ currentUser }: CommerceConfigProps) => {
    const [commerceLoggedIn, setCommerceLoggedIn] =
      useRecoilState(commerceLoggedInAtom);
    const { mutate: cartMutate } = useCart();
    const { revalidateCart } = useCommerceService();

    useEffect(() => {
      const logInCommerce = async () => {
        setCommerceLoggedIn(true);
        await cartMutate(); // gets the authenticated user on the cart
        await revalidateCart(); // revalidates the cart pricing
        await cartMutate(); // updates local display of the validated cart
      };

      if (!!currentUser && typeof commerceLoggedIn === 'undefined') {
        logInCommerce();
      }
    }, [
      cartMutate,
      commerceLoggedIn,
      currentUser,
      revalidateCart,
      setCommerceLoggedIn,
    ]);

    return null;
  }
);

CommerceConfigBase.displayName = 'CommerceConfigBase';

export const CommerceConfig = (props: CommerceConfigProps) => (
  <CommerceProvider>
    <CommerceServiceProvider>
      <CommerceConfigBase {...props} />
    </CommerceServiceProvider>
  </CommerceProvider>
);
