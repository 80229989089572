import * as Types from './types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetAlertsQueryVariables = Types.Exact<{
  type: Types.Scalars['String']['input'];
}>;


export type GetAlertsQuery = { __typename?: 'query_root', demiplane_alert: Array<{ __typename?: 'demiplane_alert', id: number, type: string, message: string, active: boolean, start_time: string, end_time: string }> };


export const GetAlertsDocument = gql`
    query getAlerts($type: String!) {
  demiplane_alert(where: {active: {_eq: true}, type: {_eq: $type}}) {
    id
    type
    message
    active
    start_time
    end_time
  }
}
    `;

export function useGetAlertsQuery(options: Omit<Urql.UseQueryArgs<GetAlertsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAlertsQuery, GetAlertsQueryVariables>({ query: GetAlertsDocument, ...options });
};