import { AppBar, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from 'next/image';

export const StyledImage = styled(Image)(() => ({}));

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.base?.base0,
  backgroundImage: 'none',
}));

export const StyledMenuButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const StyledMemberButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(90deg, #00ABFF 0%, #0D49FF 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25))`,
  color: theme.palette.common.white,
  height: 38,
  borderWidth: `1px !important`,
  borderStyle: 'solid !important',
  borderColor: theme.palette.common.white,

  '&:hover': {
    background: `linear-gradient(90deg, #00ABFF 0%, #0D49FF 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25))`,
  },
}));
