import React, { useEffect, useState } from 'react';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import { useStyles } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { useGetAlertsQuery } from 'Graphql/alert.query.generated';

interface INexusAlertProps {
  alertType: string;
  trackDismiss?: boolean;
}

const NexusAlertComp = ({ alertType, trackDismiss }: INexusAlertProps) => {
  const fullHeader = useMediaQuery('(min-width:1024px)');
  const isDesktop = useMediaQuery('(min-width:620px)');
  const classes = useStyles({ fullHeader, isDesktop });
  const [alertId, setAlertid] = useState<number | undefined>(undefined);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );
  const [alertShowing, setAlertShowing] = useState(false);

  const [alertsResult] = useGetAlertsQuery({
    variables: {
      type: alertType,
    },
    requestPolicy: 'network-only',
  });
  const { data: alertsData, fetching: alertsLoading } = alertsResult;

  useEffect(() => {
    let isMounted = true;

    const checkAlert = async () => {
      if (!alertsLoading) {
        if (!!alertsData) {
          const validAlerts = alertsData.demiplane_alert.filter(
            (alert) =>
              !!alert.active &&
              new Date() >= new Date(alert.start_time) &&
              new Date() <= new Date(alert.end_time)
          );
          if (!!validAlerts && validAlerts.length === 1) {
            if (isMounted) {
              setAlertid(validAlerts[0].id);
              setAlertMessage(validAlerts[0].message);

              if (!!trackDismiss) {
                const dismissedAlert = window.localStorage.getItem(
                  `nexus-alert:${validAlerts[0].id}`
                );

                if (!dismissedAlert) {
                  if (isMounted) {
                    setAlertShowing(true);
                  }
                }
              } else {
                if (isMounted) {
                  setAlertShowing(true);
                }
              }
            }
          }
        }
      }
    };
    checkAlert();

    return () => {
      if (!!isMounted) {
        isMounted = false;
      }
    };
  }, [alertsLoading, alertsData, trackDismiss]);

  const handleAlertClose = () => {
    if (!!trackDismiss && !!alertId) {
      window.localStorage.setItem(`nexus-alert:${alertId}`, `dismissed`);
    }

    setAlertShowing(false);
  };

  return (
    <>
      {!!alertShowing && !!alertMessage && alertMessage.length > 0 && (
        <Box
          className={`${
            classes.alertWrapperContainer
          } nexus-alert nexus-alert-${alertType.toLowerCase()}`}
        >
          <Box className={classes.alertContainer}>
            <Box
              className={`${
                classes.alertMessageContainer
              } nexus-alert-message  nexus-alert-message-${alertType.toLowerCase()}`}
            >
              {alertMessage}
            </Box>
            <Box
              className={`${
                classes.alertButtonContainer
              } nexus-alert-close-button  nexus-alert-close-button-${alertType.toLowerCase()}`}
            >
              <IconButton
                onClick={handleAlertClose}
                style={{ color: '#FFFFFF' }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export const NexusAlert = NexusAlertComp;
