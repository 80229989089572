import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { dynamicImport } from 'src/lib/dynamicImport';
import { DemiplaneAuthUser } from 'src/lib/serverSidePropsAuthCheck';

const DemiplaneMobileDropDownMenu = dynamicImport(
  'DemiplaneMobileDropDownMenu',
  () =>
    import('../DemiplaneMobileDropDownMenu').then(
      (mod) => mod.DemiplaneMobileDropDownMenu
    ),
  { ssr: false }
);

interface DemiplaneMobileMenuProps {
  currentUser?: DemiplaneAuthUser | null;
  isSubscribedBraintree: boolean;
  isSubscribedSwell: boolean;
}

export const DemiplaneMobileMenu = ({
  currentUser,
  isSubscribedBraintree,
  isSubscribedSwell,
}: DemiplaneMobileMenuProps) => {
  const [mainNavAnchorEl, setMainNavAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const handleMainNavClick = (event: React.MouseEvent<HTMLElement>) => {
    setMainNavAnchorEl(event.currentTarget);
  };

  const handleMainNavClose = () => {
    setMainNavAnchorEl(null);
  };

  return (
    <>
      <IconButton
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
        onClick={handleMainNavClick}
        role='menubutton'
        aria-haspopup='true'
        aria-expanded={!!mainNavAnchorEl}
        aria-label='demiplane navigation menu'
      >
        <MenuIcon />
      </IconButton>

      <DemiplaneMobileDropDownMenu
        anchorEl={mainNavAnchorEl}
        onClose={handleMainNavClose}
        currentUser={currentUser}
        isSubscribedBraintree={isSubscribedBraintree}
        isSubscribedSwell={isSubscribedSwell}
      />
    </>
  );
};
