import { parseHtml } from '@demiplane-dev/ui-components/src/Compendium/utils/custom-html-parser';
import { styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { ElementToolTip } from 'Components/ElementToolTip';
import { ManagedElementTooltip } from 'Components/ElementToolTip/ManagedElementTooltip';
import { AuthenticatedLayout } from 'Components/Layouts/AuthenticatedLayout';
import { NexusAlerts } from 'Components/NexusAlerts';
import {
  SiteSettingDocument,
  SiteSettingQuery,
} from 'Graphql/site-setting.query.generated';
import { withDemiplaneSSR } from 'Hocs/withDemiplaneSSR';
import { GetServerSidePropsWithCustomPageDataContext } from 'Hocs/withDemiplaneSSR/withDemiplaneSSR.types';
import IframeResizer from 'iframe-resizer-react';
import { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import { CommonPageData } from 'src/lib/pageDataLoading/pageDataLoading';
import { useUtm } from 'src/utils/utm';
import { OperationResult } from 'urql';

interface IHomeProps extends CommonPageData {
  html?: string;
  url?: string;
  metaTitle: string;
  metaDescription: string;
  metaImg: string;
}

const useStyles = makeStyles(() => ({
  scrollableContainer: {
    maxHeight: '100%',
    minHeight: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& .simplebar-content': {
      height: '100%',
    },

    '& body': {
      height: '100%',
    },

    '& html': {
      height: '100%',
    },

    '& .simplebar-offset': {
      maxWidth: '100dvw',
    },
  },
  '@global': {
    '@media screen and (max-width: 767px)': {
      '.ctacontainer': {
        marginBottom: '0 !important',
      },
    },

    '@media screen and (max-width: 991px)': {
      '.ctacontainer': {
        marginBottom: '0 !important',
      },
    },

    '@media screen and (max-width: 479px)': {
      '.ctasection': {
        marginBottom: '0 !important',
      },
    },
  },
}));

const StyledIFrame = styled(IframeResizer)(() => ({}));

const Home = ({
  html,
  url,
  metaTitle,
  metaDescription,
  metaImg,
}: IHomeProps) => {
  const classes = useStyles();
  const router = useRouter();
  const { initUtm } = useUtm(router);
  const theme = useTheme();

  useEffect(() => {
    // handle UTM saving. Wait until mounted.
    let isMounted = true;
    initUtm();

    return () => {
      if (!!isMounted) {
        isMounted = false;
      }
    };
  }, [initUtm]);

  if (!html && !url) {
    return <div>Error rendering home page</div>;
  }

  return (
    <>
      <NextSeo
        title={metaTitle}
        description={metaDescription}
        canonical={`${process.env.NEXT_PUBLIC_CLIENT_ENDPOINT}`}
        openGraph={{
          url: '/',
          title: metaTitle,
          description: metaDescription,
          type: 'website',
          images: [
            {
              url: metaImg,
              alt: metaTitle,
            },
          ],
          site_name: 'Demiplane',
        }}
        twitter={{
          handle: '@DemiplaneRPG',
          cardType: 'summary_large_image',
        }}
      />
      <NexusAlerts
        alerts={[
          { alertType: 'PROMOTION', trackDismiss: true },
          { alertType: 'PAYMENT' },
        ]}
      />
      {!!url ? (
        <StyledIFrame
          src={url}
          sx={{
            width: '1px',
            minWidth: '100dvw',
            border: 'none',
            display: 'block',
            minHeight: 'calc(100dvh - 80px)',
            [theme.breakpoints.down(1366)]: {
              minHeight: 'calc(100dvh - 72px)',
            },
          }}
          heightCalculationMethod='bodyOffset'
        />
      ) : (
        <SimpleBar className={classes.scrollableContainer}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: '100%',
              height: '100%',
            }}
          >
            <Box>
              {parseHtml({
                content: html || '',
                chunkId: 'Home',
                linkComponent: Link,
                nexusId: '',
                tooltipComponent: ElementToolTip,
                ManagedTooltip: ManagedElementTooltip,
                nexusSlug: '',
              })}
            </Box>
          </Box>
        </SimpleBar>
      )}
    </>
  );
};

export const getServerSideProps = withDemiplaneSSR(
  async (ctx: GetServerSidePropsWithCustomPageDataContext) => {
    const { urqlClient, customPageData } = ctx;

    const commonPageData = customPageData as CommonPageData;

    const props: IHomeProps = {
      ...commonPageData,
      metaTitle: 'Demiplane',
      metaDescription:
        'Find Your Next Adventure On Demiplane - Matchmaking, Video, Voice, Shared Journaling, And Much More To Come!',
      metaImg:
        'https://demiplane-static.s3-us-west-2.amazonaws.com/DemiplaneOG.jpg',
      html: '',
      url: '',
    };

    let settingName = !!process.env.NEXT_PUBLIC_FRONTEND_ENV
      ? `${process.env.NEXT_PUBLIC_FRONTEND_ENV}_home_url`
      : 'checkout_home_url';

    const urlSiteSettingResult: OperationResult<SiteSettingQuery> =
      await urqlClient!
        .query(
          SiteSettingDocument,
          {
            name: settingName,
          },
          { requestPolicy: 'network-only' }
        )
        .toPromise();

    if (!!urlSiteSettingResult.error) {
      console.log(
        `${new Date().getTime()} Error URL SiteSettingQuery`,
        urlSiteSettingResult.error
      );
    }

    if (
      !!urlSiteSettingResult.data?.demiplane_site_setting &&
      urlSiteSettingResult.data.demiplane_site_setting.length > 0 &&
      urlSiteSettingResult.data.demiplane_site_setting[0].value.length > 0
    ) {
      props.url = urlSiteSettingResult.data.demiplane_site_setting[0].value;
    } else {
      settingName = !!process.env.NEXT_PUBLIC_FRONTEND_ENV
        ? `home_html_${process.env.NEXT_PUBLIC_FRONTEND_ENV}`
        : 'home_html_checkout';

      const htmlSiteSettingResult: OperationResult<SiteSettingQuery> =
        await urqlClient!
          .query(
            SiteSettingDocument,
            {
              name: settingName,
            },
            { requestPolicy: 'network-only' }
          )
          .toPromise();

      if (!!htmlSiteSettingResult.error) {
        console.log(
          `${new Date().getTime()} Error HTML SiteSettingQuery`,
          htmlSiteSettingResult.error
        );
      }

      if (
        !!htmlSiteSettingResult.data?.demiplane_site_setting &&
        htmlSiteSettingResult.data.demiplane_site_setting.length > 0
      ) {
        props.html = htmlSiteSettingResult.data.demiplane_site_setting[0].value;
      }
    }

    return { props };
  },
  { auth: { enabled: true }, common: true }
);

Home.getLayout = (page: NextPage, pageProps: IHomeProps) => {
  return (
    <AuthenticatedLayout pageProps={pageProps}>{page}</AuthenticatedLayout>
  );
};

export default Home;
