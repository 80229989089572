import React from 'react';
import { useCurrentUser } from 'Hooks/CurrentUser';
import { dynamicImport } from 'src/lib/dynamicImport';
import { CommerceConfig } from 'Components/CommerceConfig';

const ChatConfig = dynamicImport(
  'ChatConfig',
  () => import('Components/ChatConfig').then((mod) => mod.ChatConfig),
  { ssr: false }
);

const OneSignalConfig = dynamicImport(
  'OneSignalConfig',
  () => import('Components/OneSignalConfig').then((mod) => mod.OneSignalConfig),
  { ssr: false }
);

const AppSubscriptions = dynamicImport(
  'AppSubscriptions',
  () =>
    import('Components/AppSubscriptions').then((mod) => mod.AppSubscriptions),
  { ssr: false }
);

const LastAccess = dynamicImport(
  'LastAccess',
  () => import('Components/LastAccess').then((mod) => mod.LastAccess),
  { ssr: false }
);

const EventListener = dynamicImport(
  'EventListener',
  () => import('Components/EventListener').then((mod) => mod.EventListener),
  { ssr: false }
);

export const AuthenticatedSetup = () => {
  const { currentUser } = useCurrentUser();

  return (
    <>
      {!!currentUser && (
        <>
          <ChatConfig currentUser={currentUser} />
          <OneSignalConfig currentUser={currentUser} />
          <AppSubscriptions currentUser={currentUser} />
          <LastAccess currentUser={currentUser} />
          <EventListener currentUser={currentUser} />
          <CommerceConfig currentUser={currentUser} />
        </>
      )}
    </>
  );
};
