import React, { useState } from 'react';
import { Toolbar, Container, Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { StyledAppBar, StyledImage } from './DemiplaneTopNavigation.styles';
import logoImage from 'public/logo/demiplane.png';
import { topNavTheme } from 'src/theme/demiplaneTheme';
import Link from 'next/link';
import { DemiplaneNavigationButtons } from '../DemiplaneNavigationButtons';
import { UserButtons } from '../../Shared/UserButtons';
import { DemiplaneMobileMenu } from '../DemiplaneMobileMenu';
import { CommonTopNavData } from 'src/lib/pageDataLoading/pageDataLoading';
import { useRouter } from 'next/router';

interface DemiplaneTopNavigationProps {
  topNav: CommonTopNavData | undefined;
}

const DemiplaneTopNavigationBase = ({
  topNav,
}: DemiplaneTopNavigationProps) => {
  const router = useRouter();
  const { pathname } = router;
  const [showSearch, setShowSearch] = useState<boolean>(false);

  if (!topNav) {
    return (
      <StyledAppBar position='static' sx={{ height: { xs: 72, lg: 80 } }}>
        <Container
          sx={{ height: { xs: 72, lg: 80 } }}
          maxWidth='xl'
          data-cy='demiplane-top-nav'
        >
          <Toolbar
            disableGutters
            sx={{
              height: { xs: 72, lg: 80 },
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              position='relative'
              display='flex'
              alignItems='center'
              flexGrow={1}
            >
              <Link href='/home' shallow={pathname === '/home'}>
                <StyledImage
                  alt='demiplane logo'
                  src={logoImage.src}
                  width={180}
                  height={54}
                  style={{ cursor: 'pointer', margin: 0 }}
                  data-cy='top-nav-demiplane-logo'
                  priority
                  sx={{
                    width: { xs: 95, sm: 126, lg: 180 },
                    height: { xs: 30, sm: 39, lg: 54 },
                  }}
                />
              </Link>
            </Box>
          </Toolbar>
        </Container>
      </StyledAppBar>
    );
  }

  const {
    gamesDisplays,
    currentUser,
    isSubscribedBraintree,
    isSubscribedSwell,
    isCommerceEnabled,
  } = topNav;

  return (
    <StyledAppBar position='static' sx={{ height: { xs: 72, lg: 80 } }}>
      <Container
        sx={{ height: { xs: 72, lg: 80 } }}
        maxWidth='xl'
        data-cy='demiplane-top-nav'
      >
        <Toolbar
          disableGutters
          sx={{
            height: { xs: 72, lg: 80 },
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            position='relative'
            display='flex'
            alignItems='center'
            flexGrow={1}
          >
            <DemiplaneMobileMenu
              currentUser={currentUser}
              isSubscribedBraintree={isSubscribedBraintree}
              isSubscribedSwell={isSubscribedSwell}
            />
            <Link href='/home' shallow={pathname === '/home'}>
              <StyledImage
                alt='demiplane logo'
                src={logoImage.src}
                width={180}
                height={54}
                style={{ cursor: 'pointer', margin: 0 }}
                data-cy='top-nav-demiplane-logo'
                priority
                sx={{
                  width: { xs: 95, sm: 126, lg: 180 },
                  height: { xs: 30, sm: 39, lg: 54 },
                }}
              />
            </Link>
            <DemiplaneNavigationButtons
              gamesDisplays={gamesDisplays}
              currentUser={currentUser}
              isSubscribedBraintree={isSubscribedBraintree}
              isSubscribedSwell={isSubscribedSwell}
              showSearch={showSearch}
            />
          </Box>

          <UserButtons
            currentUser={currentUser}
            isCommerceEnabled={isCommerceEnabled}
            showSearch={showSearch}
            setShowSearch={setShowSearch}
          />
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export const DemiplaneTopNavigation = (props: DemiplaneTopNavigationProps) => (
  <ThemeProvider theme={topNavTheme}>
    <DemiplaneTopNavigationBase {...props} />
  </ThemeProvider>
);
