import { NextRouter } from 'next/router';

export const useUtm = (router: NextRouter) => {
  const initUtm = () => {
    let utmSource = null;
    if (router.query.utm_source) {
      utmSource = router.query.utm_source;
    }
    let utmMedium = null;
    if (router.query.utm_medium) {
      utmMedium = router.query.utm_medium;
    }
    let utmCampaign = null;
    if (router.query.utm_campaign) {
      utmCampaign = router.query.utm_campaign;
    }

    // handle if empty
    if (!utmSource || !utmMedium || !utmCampaign) {
      if (router.query.from) {
        const allParams = window.location.href.split('?');
        let fromLocation = -1;
        // eslint-disable-next-line array-callback-return
        allParams.find((elem, idx) => {
          if (elem.indexOf('from')) {
            fromLocation = idx;
            return idx;
          }
          return false;
        });
        let searchParams = new URLSearchParams(allParams[fromLocation]);

        if (searchParams.has('utm_source')) {
          utmSource = searchParams.get('utm_source');
        }
        if (searchParams.has('utm_medium')) {
          utmMedium = searchParams.get('utm_medium');
        }
        if (searchParams.has('utm_campaign')) {
          utmCampaign = searchParams.get('utm_campaign');
        }
      }
    }

    if (!!utmSource && !!utmMedium && !!utmCampaign) {
      window.localStorage.setItem(
        'utm',
        JSON.stringify({
          utmSource,
          utmMedium,
          utmCampaign,
        })
      );
    }
  };

  return { initUtm };
};
