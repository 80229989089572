import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import { GamesDisplayFieldsFragment } from 'Graphql/games-display.fragment.generated';
import React, { useState } from 'react';
import { MenuButton } from '../../Shared/MenuButton';
import { MenuMemberButton } from '../../Shared/MenuMemberButton';
import { DemiplaneAuthUser } from 'src/lib/serverSidePropsAuthCheck';
import { dynamicImport } from 'src/lib/dynamicImport';

const GamesDropDownMenu = dynamicImport(
  'GamesDropDownMenu',
  () => import('../GamesDropDownMenu').then((mod) => mod.GamesDropDownMenu),
  { ssr: false }
);

interface DemiplaneNavigationButtonsProps {
  gamesDisplays: GamesDisplayFieldsFragment[];
  currentUser?: DemiplaneAuthUser | null;
  isSubscribedSwell: boolean;
  isSubscribedBraintree: boolean;
  showSearch?: boolean;
}

export const DemiplaneNavigationButtons = ({
  gamesDisplays,
  currentUser,
  isSubscribedBraintree,
  isSubscribedSwell,
  showSearch,
}: DemiplaneNavigationButtonsProps) => {
  const [gamesMenuAnchorEl, setGamesMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleGamesClick = (event: React.MouseEvent<HTMLElement>) => {
    setGamesMenuAnchorEl(event.currentTarget);
  };

  const handleGamesMenuClose = () => {
    setGamesMenuAnchorEl(null);
  };

  const gamesDisplayDesktop = gamesDisplays.find((gamesDisplay) => {
    return gamesDisplay.usage.includes('desktop');
  });

  return (
    <Box
      alignItems='center'
      flexGrow={1}
      className='demiplane-nav-menu nav-desktop'
      display={{ xs: 'none', md: !!showSearch ? 'none' : 'flex' }}
    >
      <MenuButton
        sx={{ mx: 1.5 }}
        id='games-menu-button'
        aria-expanded={!!gamesMenuAnchorEl ? 'true' : undefined}
        aria-label='games menu button'
        aria-haspopup='true'
        aria-controls={gamesMenuAnchorEl ? 'games-menu' : undefined}
        endIcon={
          !!gamesMenuAnchorEl ? (
            <ArrowDropUpIcon sx={{ color: 'demi.blue' }} />
          ) : (
            <ArrowDropDownIcon sx={{ color: 'demi.blue' }} />
          )
        }
        onClick={handleGamesClick}
        data-cy='top-nav-games-btn'
        className='demiplane-nav-menu-btn games-btn nav-desktop'
      >
        Games
      </MenuButton>
      <GamesDropDownMenu
        anchorEl={gamesMenuAnchorEl}
        onClose={handleGamesMenuClose}
        gamesDisplay={gamesDisplayDesktop}
      />
      <MenuButton
        role='menuitem'
        aria-label='characters'
        link
        linkUrl='/characters'
        data-cy='top-nav-characters-btn'
        sx={{ mx: 1.5 }}
        className='demiplane-nav-menu-btn characters-btn nav-desktop'
      >
        Characters
      </MenuButton>
      <MenuButton
        role='menuitem'
        aria-label='groups'
        link
        linkUrl='/groups'
        data-cy='top-nav-groups-btn'
        sx={{ mx: 1.5 }}
        className='demiplane-nav-menu-btn groups-btn nav-desktop'
      >
        Groups
      </MenuButton>
      {/* <MenuButton
        role='menuitem'
        aria-label='news'
        link
        linkUrl='https://www.demiplane.com/blog'
        data-cy='top-nav-news-btn'
        sx={{ mx: 1.5 }}
        className='demiplane-nav-menu-btn news-btn nav-desktop'
      >
        News
      </MenuButton> */}
      <MenuButton
        role='menuitem'
        aria-label='forums'
        link
        linkUrl='https://forums.demiplane.com/'
        sx={{ mx: 1.5 }}
        data-cy='top-nav-forums-btn'
        className='demiplane-nav-menu-btn forums-btn nav-desktop'
      >
        Forums
      </MenuButton>
      {!!currentUser && !isSubscribedSwell && !isSubscribedBraintree && (
        <MenuMemberButton
          role='button'
          aria-label='subscribe now'
          link
          linkUrl='/subscription'
          sx={{ mx: 1.5, display: { xs: 'none', lg: 'block' } }}
          data-cy='header-become-member-btn'
          className='demiplane-nav-menu-btn become-member-btn nav-desktop'
        >
          Subscribe Now
        </MenuMemberButton>
      )}
    </Box>
  );
};
