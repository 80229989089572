import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  alertWrapperContainer: {
    width: '100%',
    backgroundColor: '#33A6D7',
    zIndex: 10,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  alertContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    maxWidth: 1200,
    width: '100%',
  },
  alertMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Barlow',
    fontWeight: 500,
    fontSize: 14,
    color: '#FFFFFF',
    flexGrow: 1,
    justifyContent: 'center',
  },
  alertButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));
