import React from 'react';
import { NexusAlert } from 'Components/NexusAlert';
import { Box } from '@mui/material';

interface INexusAlertsProps {
  alerts: {
    alertType: string;
    trackDismiss?: boolean;
  }[];
}

const NexusAlertsComp = ({ alerts }: INexusAlertsProps) => {
  return (
    <Box position='absolute' width='100%' zIndex={10}>
      {alerts.map((alert) => (
        <NexusAlert key={alert.alertType} {...alert} />
      ))}
    </Box>
  );
};

export const NexusAlerts = NexusAlertsComp;
